.statusWrapper {
  display: flex;
}

.statusContainer {
  display: flex;
  padding: 4px 6px;
}

.statusContainer p {
  font-weight: 500;
}

.statusContainer *:first-child {
  margin-right: 8px;
}

.success {
  color: #43a047;
}

.successFill {
  background-color: rgba(67, 160, 71, 0.1);
}

.error {
  color: #b00020;
}

.errorFill {
  background-color: rgba(176, 0, 32, 0.1);
}

.created {
  color: #ead200;
}

.createdFill {
  background-color: rgba(234, 210, 0, 0.1);
}

.pending {
  color: #e9ab4e;
}

.pendingFill {
  background-color: rgba(233, 171, 78, 0.1);
}

.canceled {
  color: #1b1635;
}

.canceledFill {
  background-color: rgba(27, 22, 53, 0.1);
}

.inProgress {
  color: #f8d81b;
}

.inProgressFill {
  background-color: rgba(77, 154, 200, 0.1);
}

.waiting {
  color: #ec981a;
}

.waitingFill {
  background-color: rgba(233, 171, 78, 0.1);
}
